import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import CheckIcon from 'src/assets/icons/check-icon.svg?react';
import { useItemsSelection } from 'src/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import LoadingButton from 'src/components/LoadingButton';
import { SideModalSkeleton } from 'src/components/SideModal';
import Tooltip from 'src/components/Tooltip';
import {
  useConfirmLoanAppCreditorsMutation,
  useGetLoanAppCreditorsQuery,
  LoanAppStatus,
} from 'src/entities/loanApps';

type Props = {
  id: number;
  loanAppStatusCode: LoanAppStatus;
};

function isApprovingAvailable(loanAppStatusCode: LoanAppStatus) {
  return loanAppStatusCode === LoanAppStatus.SourcingOfCapital;
}

function LoanAppDetailsCreditors({ id, loanAppStatusCode }: Props) {
  const { showAlert } = useAlert();
  const { data, isLoading } = useGetLoanAppCreditorsQuery(id);
  const [confirm, { isLoading: isSaving }] =
    useConfirmLoanAppCreditorsMutation();
  const { selected, selectedArr, onSelect } = useItemsSelection();

  const handleConfirm = () =>
    confirm({ id, creditorIds: selectedArr })
      .unwrap()
      .then(() =>
        showAlert({
          type: AlertType.Success,
          text: 'The list of debt investors has been approved and shared with the Monivolt Investment Team to execute.',
        }),
      );

  if (isLoading) {
    return <SideModalSkeleton />;
  }
  return (
    <>
      <Typography color="text.secondary" variant="body2" mb={2}>
        Please select the debt investors you would like us to approach and click
        the &quot;Approve Debt Investors&quot; button. We will only reach out to
        the partners you select.
      </Typography>
      <LoadingButton
        size="small"
        startIcon={<SvgIcon component={CheckIcon} />}
        disabled={
          !isApprovingAvailable(loanAppStatusCode) || !selectedArr.length
        }
        onClick={handleConfirm}
        loading={isSaving}
        fullWidth
      >
        Approve debt investors
      </LoadingButton>
      <List sx={{ typography: 'caption', color: 'text.secondary' }}>
        {data?.map(
          ({ id, creditorId, creditorFullName, isConfirmedByBorrower }) => (
            <ListItem key={id} dense>
              <Tooltip
                disabled={
                  !isApprovingAvailable(loanAppStatusCode) ||
                  !isConfirmedByBorrower
                }
                title="The selected debt investor has been already approved"
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disabled={
                      !isApprovingAvailable(loanAppStatusCode) ||
                      isConfirmedByBorrower
                    }
                    defaultChecked={isConfirmedByBorrower}
                    value={selected[creditorId]}
                    onChange={() => onSelect(creditorId)}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText id={String(id)} primary={creditorFullName} />
            </ListItem>
          ),
        )}
      </List>
    </>
  );
}

export default LoanAppDetailsCreditors;
